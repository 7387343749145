/*html, body { height: 100%; }
body {
  background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
  overflow: hidden;
}*/
* {
  font-family: "Montserrat", sans-serif;
}

.background_color {
  background-color: #815854;
}
.font_color {
  color: #f9ebde;
}
.card {
  border: 1px solid black;
  max-width: 30%;
  margin: 10px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box; /* Including padding within the element's width */
  display: inline-block; /* Display the cards as inline blocks */
  vertical-align: top; /* Align the cards to the top */
}

.card-row {
  text-align: center; /* Center the cards within the row */
}
/* .card {
  border: black;
  max-width: 30%;
  margin: auto;
  text-align: center;
  padding: 20px;
  vertical-align: top;
}
.card-row:after {
  content: "";
  display: inline-block;
  clear: both;
} */
.button_container {
}
.card_button {
  padding: 7px;
  margin: 5px;
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
.git-links {
  font-size: 20px;
}
.ProjectImg {
  max-width: 100%;
}
.SectionTextContainer {
  display: flex;
  flex-direction: column;
}
.SectionProjectHeader {
  text-align: left;
}

.nav-items {
  margin: 0 20px;
  text-decoration: none !important;
  color: #f9ebde !important;
}
.navbar-brand {
  margin-left: 20px;
}
.my-name-is {
  margin-left: 200px;
  font-size: 25px;
  margin-top: 50px;
  animation-duration: 250ms;
  animation-delay: 500ms;
}
.big-heading {
  font-weight: 700;
  font-size: 75px;
  margin-left: 200px;
  animation-duration: 350ms;
  animation-delay: 1000ms;
}
.big-heading2 {
  font-weight: 700;
  font-size: 75px;
  margin-left: 200px;
  animation-duration: 350ms;
  animation-delay: 1500ms;
}
.I-am-a {
  margin-left: 200px;
  font-size: 25px;
  animation-duration: 450ms;
  animation-delay: 2000ms;
}
#about {
  animation-duration: 550ms;
  margin-top: 100px;
  animation-delay: 2500ms;
}
/*Animaton*/
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.links {
  text-decoration: none;
  color: #f9ebde;
  justify-content: center;
  align-items: center;
  display: flex;
}
.underline {
  display: inline-block;
  position: relative;
}
.underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f9ebde;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}
.underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.container {
  margin: 50px;
  display: flex;
}

.logo {
  margin-right: 50px;
  max-width: 35%;
}

.div1 {
  max-width: 80%;
}
